import { defineComponent, defineAsyncComponent, reactive, toRefs, watch, onMounted } from 'vue';
import { useDebounce } from '@/hooks/core/useDebounce';
import { ElSpace } from 'element-plus';
import NewNotice from './FenceNewWarnDialog.vue';
import { drawTypeOptions, fenceTypeOptions, alarmTypeOptions, columns } from './options';
import api from '@/api/safetyFence';
import { isNullOrUnDef } from '@/utils/is';
import DeletePopover from '@/components/DeletePopover';
import { useMessage } from '@/hooks/web/useMessage';
import BindingDialog from '@/components/Binding';
import dayjs from "dayjs";
import { Search } from "@element-plus/icons-vue";
import httpApi from "@/api/httpApi";
export default defineComponent({
  name: 'SafetyFenceTable',
  components: {
    ElSpace,
    NewNotice,
    DeletePopover,
    BindingDialog,
    FenceDialog: defineAsyncComponent(() => import('./FenceDialog.vue')),
    BasicTemplate: defineAsyncComponent(() => import('@/components/BasicTemplateTablePage/index.vue')),
    MapSearch: defineAsyncComponent(() => import('@/components/MapSearch/index.vue'))
  },
  emits: ['add', 'rowClick'],
  props: {
    mapZoom: {
      type: Number,
      default: 12
    },
    mapCenter: {
      type: Object,
      default: null
    },
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  setup(props, {
    emit
  }) {
    const messageBox = useMessage();
    const refData = reactive({
      refMapSearch: null,
      loading: false,
      total: 0,
      current: 1,
      pageSize: 50,
      pathName: '',
      useType: 1,
      tableData: [],
      tableColumns: columns,
      bindProp: {
        visible: false,
        pathId: 0,
        type: 'student'
      },
      fenceProp: {
        visible: false,
        info: null
      }
    });
    watch(() => refData.pathName, value => {
      if (isNullOrUnDef(value)) return;
      reload();
    });
    function openFenceDailog(info) {
      refData.fenceProp.info = {
        ...info,
        attTime: info.attTime && info.attTime.length ? info.attTime.map(item => ({
          start: new Date(dayjs().format(`YYYY-MM-DD ${item.attStart}`)),
          end: new Date(dayjs().format(`YYYY-MM-DD ${item.attEnd}`))
        })) : [{
          start: new Date(dayjs().format(`YYYY-MM-DD 00:00`)),
          end: new Date(dayjs().format(`YYYY-MM-DD 23:59`))
        }],
        date: info.attTime && info.attTime.length ? info.attTime[0].dateTime == '0' ? [] : info.attTime[0].dateTime.split(',').map(item => Number(item)) : []
      };
      refData.fenceProp.visible = true;
      emit('add');
    }
    function reload() {
      refData.current = 1;
      debounceOnSearch();
    }
    const onSearch = async () => {
      refData.loading = true;
      try {
        emit('rowClick', null);
        const result = await api.getAllByPage({
          pathName: refData.pathName,
          pageNo: refData.current,
          pageSize: refData.pageSize,
          useType: refData.useType
        });
        if (result.code === 0) {
          refData.tableData = result.data.list;
          refData.total = result.data.total;
        } else {
          refData.tableData = [];
          refData.total = 0;
        }
      } catch (error) {
        console.error(error);
      } finally {
        refData.loading = false;
      }
    };
    function onRowClick(row) {
      emit('rowClick', row);
    }
    const currentChange = val => {
      refData.current = val;
      debounceOnSearch();
    };
    const pageSizeChange = val => {
      refData.pageSize = val;
      debounceOnSearch();
    };
    async function handleDelete(row) {
      try {
        const result = await api.delete({
          pathId: row.pathId
        });
        if (result.code === 0) {
          debounceOnSearch();
          emit('rowClick', null);
        }
        messageBox[result.code == 0 ? 'success' : 'warning'](result.code == 0 ? '删除成功' : result.msg);
      } catch (error) {
        messageBox.error(error);
      } finally {}
    }
    function onBinding(row) {
      refData.bindProp.type = ['student', 'org', 'org'][row.useType - 1];
      refData.bindProp.pathId = row.pathId;
      refData.bindProp.visible = true;
    }
    //切换查询类型
    const handleSwitch = type => {
      refData.useType = type;
      onSearch();
    };
    // 添加危险区域
    const handleMapSearch = () => {
      refData.refMapSearch.visible = true;
    };
    // 保存危险区域围栏
    const onConfirmDanger = async params => {
      const {
        msg,
        code
      } = await httpApi.saveDangerBatch(params);
      messageBox[code === 0 ? "success" : "warning"](code === 0 ? "保存成功" : msg);
    };
    const [debounceOnSearch] = useDebounce(onSearch, 200);
    const [debounceOnRowClick] = useDebounce(onRowClick, 300);
    const [debounceOnCurrentChange] = useDebounce(currentChange, 300);
    const [debounceOnDelete] = useDebounce(handleDelete, 300);
    onMounted(() => debounceOnSearch());
    return {
      ...toRefs(refData),
      drawTypeOptions,
      fenceTypeOptions,
      alarmTypeOptions,
      currentChange,
      pageSizeChange,
      debounceOnSearch,
      debounceOnDelete,
      reload,
      debounceOnCurrentChange,
      openFenceDailog,
      debounceOnRowClick,
      onBinding,
      Search,
      handleMapSearch,
      onConfirmDanger,
      handleSwitch
    };
  }
});