import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Binding = _resolveComponent("Binding");
  const _component_Eldialog = _resolveComponent("Eldialog");
  return _openBlock(), _createBlock(_component_Eldialog, _mergeProps(_ctx.$attrs, {
    visible: _ctx.show,
    "onUpdate:visible": _cache[0] || (_cache[0] = $event => _ctx.show = $event),
    header: _ctx.title,
    onConfirmOk: _ctx.confirm,
    width: "480px"
  }), {
    form: _withCtx(() => [_createVNode(_component_Binding, {
      ref: "refBinding",
      class: "dialog_content"
    }, null, 512)]),
    footer: _withCtx(() => []),
    _: 1
  }, 16, ["visible", "header", "onConfirmOk"]);
}