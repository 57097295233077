import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4b848e36"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "label"
};
const _hoisted_2 = {
  style: {
    "text-align": "right",
    "margin": "0"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_popover = _resolveComponent("el-popover");
  return _openBlock(), _createBlock(_component_el_popover, {
    placement: "top",
    width: _ctx.width,
    visible: _ctx.visible,
    "onUpdate:visible": _cache[2] || (_cache[2] = $event => _ctx.visible = $event)
  }, {
    reference: _withCtx(() => [_createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = _withModifiers($event => _ctx.visible = true, ["stop", "prevent"]))
    }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_Icon, {
      name: "Warning",
      class: "warn_icon"
    }), _createTextVNode("确定删除吗? ")]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
      size: "small",
      type: "text",
      onClick: _cache[0] || (_cache[0] = $event => _ctx.visible = false)
    }, {
      default: _withCtx(() => [_createTextVNode("取消")]),
      _: 1
    }), _createVNode(_component_el_button, {
      type: "primary",
      size: "small",
      onClick: _ctx.handleOk
    }, {
      default: _withCtx(() => [_createTextVNode("确定")]),
      _: 1
    }, 8, ["onClick"])])]),
    _: 3
  }, 8, ["width", "visible"]);
}