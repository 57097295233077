import { GET, POSTBody } from '../apiFn';
import url from '../url';
import APIType from './types';

export default {
  add: (params: APIType.AddParam): Promise<APIType.BaseRes> => POSTBody(url.addFence, params),
  update: (params: APIType.UpdateParam): Promise<APIType.BaseRes> => POSTBody(url.updateFence, params),
  delete: (params: APIType.DeleteParam): Promise<APIType.BaseRes> => GET(url.deleteFenceById, params),
  getAll: (params: APIType.GetAllParam): Promise<APIType.GetAllRes> => GET(url.getAllFences, params),
  getAllByPage: (params: APIType.GetAllPageParam): Promise<APIType.GetAllPageRes> => GET(url.getAllFenceByPage, params),
  bindingDept: (params: APIType.BindingSchoolParam): Promise<APIType.BaseRes> => POSTBody(url.bindingDept, params),
  bindingCard: (params: APIType.BindingCardParam): Promise<APIType.BaseRes> => POSTBody(url.bindingCard, params),
  getAllbinding: (params: APIType.GetAllBindingParam): Promise<APIType.GetAllBindingRes> => GET(url.getAllBinding, params),
};