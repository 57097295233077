import { defineComponent, reactive, toRefs } from 'vue';
import { ElPopover } from 'element-plus';
export default defineComponent({
  name: 'DeletePopover',
  components: {
    ElPopover
  },
  emits: ['ok'],
  props: {
    width: {
      type: [String, Number],
      default: 160
    }
  },
  setup(props, {
    emit
  }) {
    const refData = reactive({
      visible: false
    });
    function handleOk() {
      refData.visible = false;
      emit('ok');
    }
    return {
      ...toRefs(refData),
      handleOk
    };
  }
});