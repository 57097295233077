import "core-js/modules/es.array.push.js";
import { defineAsyncComponent, defineComponent, reactive, toRefs } from 'vue';
import { ElCard, ElSpace } from 'element-plus';
import List from '@/components/List';
import api from '@/api/safetyFence';
import { useMessage } from '@/hooks/web/useMessage';
export default defineComponent({
  name: 'Binding',
  components: {
    ElCard,
    ElSpace,
    OrganizeSelect: defineAsyncComponent(() => import('@/components/OrganizeSelect/index.vue')),
    List
  },
  setup(props) {
    const messageBox = useMessage();
    const refData = reactive({
      pathId: 0,
      cardList: [],
      deptList: [],
      type: 'student'
    });
    async function getBinding(pathId) {
      refData.cardList = [];
      refData.deptList = [];
      refData.pathId = pathId;
      const result = await api.getAllbinding({
        pathId
      });
      if (result.code !== 0) return null;
      const {
        cardList,
        deptList
      } = result.data;
      refData.cardList = cardList.map(item => ({
        id: item.objectid,
        name: item.objectname
      }));
      refData.deptList = deptList.map(item => ({
        id: item.holdid,
        name: item.holdname
      }));
    }
    async function bindingDept() {
      const holdIds = refData.deptList.map(school => school.id);
      const result = await api.bindingDept({
        pathId: refData.pathId,
        holdIds
      });
      if (result.code !== 0) {
        messageBox.warning(result.msg);
        return;
      }
      // messageBox.success('单位绑定成功');
    }
    async function bindingCard() {
      const objectid = refData.cardList.map(card => card.id);
      const result = await api.bindingCard({
        pathId: refData.pathId,
        objectid
      });
      if (result.code !== 0) {
        messageBox.warning(result.msg);
        return;
      }
      // messageBox.success('绑定成功');
    }
    function onOrgDblclick(item) {
      if (item.type === 'org') {
        if (refData.deptList.some(p => p.id === item.id)) return;
        refData.deptList.push({
          id: item.id,
          name: item.name
        });
      }
    }
    function onClickDevice(items) {
      const cardIds = refData.cardList.map(item => item.id);
      items.map(p => {
        !cardIds.includes(p.objectid) && refData.cardList.push({
          id: p.objectid,
          name: p.objectname
        });
      });
    }
    function onDeleteSchool(item) {
      refData.deptList = refData.deptList.filter(school => school.id !== item.id);
    }
    function onDeleteCard(item) {
      refData.cardList = refData.cardList.filter(card => card.id !== item.id);
    }
    async function save() {
      try {
        refData.type == 'org' ? await bindingDept() : await bindingCard();
        messageBox.success('绑定成功');
        return true;
      } catch (err) {
        messageBox.error(err);
      }
      return false;
    }
    //绑定单位
    function handleDoubleClick(item) {
      const len = refData.deptList.some(school => school.id == item.id);
      !len && refData.deptList.push(item);
    }
    return {
      ...toRefs(refData),
      getBinding,
      save,
      onOrgDblclick,
      onClickDevice,
      onDeleteSchool,
      onDeleteCard,
      handleDoubleClick
    };
  }
});