import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, mergeProps as _mergeProps } from "vue";
const _hoisted_1 = {
  class: "box_map"
};
const _hoisted_2 = {
  class: "bmap"
};
const _hoisted_3 = {
  key: 1,
  id: "searchInput",
  class: "searchInput",
  type: "text",
  placeholder: "搜索目标地址"
};
const _hoisted_4 = {
  class: "table"
};
const _hoisted_5 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BSJMap = _resolveComponent("BSJMap");
  const _component_Icon = _resolveComponent("Icon");
  const _component_BasicTemplate = _resolveComponent("BasicTemplate");
  const _component_Eldialog = _resolveComponent("Eldialog");
  return _openBlock(), _createBlock(_component_Eldialog, _mergeProps(_ctx.$attrs, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = $event => _ctx.visible = $event),
    title: "查看危险区域",
    onConfirmOk: $setup.confirm,
    width: "1400px",
    draggable: ""
  }), {
    form: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_BSJMap, {
      ref: "refMap",
      zoom: 12,
      infoWidth: 460,
      infoHeight: 200
    }, null, 512), $props.isSearch ? (_openBlock(), _createBlock(_component_Icon, {
      key: 0,
      name: "LocationFilled",
      style: {
        "font-size": "28px"
      },
      onClick: $setup.handleDraw,
      class: "point"
    }, null, 8, ["onClick"])) : _createCommentVNode("", true), $props.isSearch ? (_openBlock(), _createElementBlock("input", _hoisted_3)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_BasicTemplate, {
      tableColumns: _ctx.tableColumns,
      tableData: _ctx.tableData,
      total: _ctx.total,
      height: '570px',
      loading: _ctx.loading,
      pageSize: _ctx.pageSize,
      isPage: _ctx.isPage,
      onHandleSelectionChange: $setup.handleSelectionChange,
      onTableRowClick: $setup.tableRowClick
    }, {
      index: _withCtx(({
        $index
      }) => [_createTextVNode(_toDisplayString((_ctx.current - 1) * _ctx.pageSize + ($index + 1)), 1)]),
      type: _withCtx(({
        row
      }) => [_createTextVNode(_toDisplayString(`${row.typeName ? '危险水域' : '娱乐场所'}`), 1)]),
      operate: _withCtx(({
        row
      }) => [_ctx.tableSelect.map(item => item.uid).includes(row.uid) ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: "btn",
        onClick: _withModifiers($event => $setup.handleEdit(row), ["stop"])
      }, _toDisplayString(row.id === _ctx.activeId ? '关闭' : '') + "编辑", 9, _hoisted_5)) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["tableColumns", "tableData", "total", "loading", "pageSize", "isPage", "onHandleSelectionChange", "onTableRowClick"])])])]),
    footer: _withCtx(() => []),
    _: 1
  }, 16, ["visible", "onConfirmOk"]);
}