import { defineComponent, toRefs, reactive, computed, watch } from 'vue';
import Eldialog from '@/components/Eldialog/index.vue';
import { ElRadio } from 'element-plus';
import { drawTypeOptions } from './options';
export default defineComponent({
  components: {
    Eldialog,
    ElRadio
  },
  emits: ['ok', 'update:visible'],
  props: {
    Info: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {
    emit
  }) {
    const refData = reactive({
      formData: null,
      title: '新增提示',
      confirm() {
        show.value = false;
        emit('ok', refData.formData.type);
      },
      init() {
        refData.formData = {
          type: 1
        };
      }
    });
    // 初始化表单
    refData.init();
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit('update:visible', val);
      }
    });
    watch(() => props.visible, val => {
      if (val) {
        refData.init();
      }
    }, {
      immediate: true
    });
    return {
      ...toRefs(refData),
      show,
      drawTypeOptions
    };
  }
});