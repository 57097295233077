import "core-js/modules/es.array.push.js";
import { reactive, toRefs, watch, nextTick } from "vue";
import BSJMap from "@/components/BSJMap";
import Eldialog from "@/components/Eldialog/index.vue";
import BasicTemplate from "@/components/BasicTemplateTablePage/index.vue";
import httpApi from "@/api/httpApi";
import API from "@/api/education";
import { useMessage } from '@/hooks/web/useMessage';
import { bdEncrypt, bdDecrypt } from '@/utils/bdGPS';
export default {
  name: "MapSearch",
  props: {
    // 是否显示搜索框
    isSearch: {
      type: Boolean,
      default: true
    },
    // 是否存在保存
    isConfirm: {
      type: Boolean,
      default: false
    },
    //单位ID
    schoolId: {
      type: Number,
      default: 1
    },
    isOperate: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Eldialog,
    BSJMap,
    BasicTemplate
  },
  emits: ['handleSuccess'],
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const refData = reactive({
      visible: false,
      refMap: null,
      mapDraw: null,
      lngLat: [],
      tableData: [],
      tableColumns: [{
        type: 'selection',
        title: '勾选',
        align: 'center',
        key: 'checkbox',
        width: 60,
        visible: true
      }, {
        title: "序号",
        align: "center",
        key: "index",
        width: 50,
        slots: {
          customRender: "index"
        },
        visible: true
      }, {
        title: '围栏类型',
        align: 'center',
        minwidth: 100,
        key: 'type',
        slots: {
          customRender: "type"
        },
        visible: true
      }, {
        title: '围栏名称',
        align: 'center',
        minwidth: 160,
        key: 'name',
        visible: true
      }, {
        title: '操作',
        align: 'center',
        minwidth: 80,
        key: 'operate',
        slots: {
          customRender: 'operate'
        },
        visible: props.isOperate
      }],
      tableSelect: [],
      loading: false,
      total: 0,
      current: 1,
      pageSize: 25,
      isPage: false,
      coverArr: [],
      activeId: null,
      backupCover: null
    });
    watch(() => refData.visible, val => {
      refData.tableData = [];
      refData.coverArr = [];
      refData.tableSelect = [];
      val && setTimeout(() => {
        props.isSearch ? initMapSearch() : getByschoolIdArea(props.schoolId);
      }, 1000);
    });
    //获取地图对象
    function getMap() {
      return [refData.refMap.getMap(), refData.refMap];
    }
    ;
    //初始化地图搜索组件
    const initMapSearch = () => {
      const map = getMap()[0].instance;
      const ac = new BMap.Autocomplete({
        input: "searchInput",
        location: map
      });
      ac.show();
      ac.addEventListener('onconfirm', function (e) {
        var _value = e.item.value;
        const myValue = _value.province + _value.city + _value.district + _value.street + _value.business;
        setPlace(myValue);
      });
      function setPlace(Value) {
        handleClearOverlays(); //清除地图上所有覆盖物
        function myFun() {
          var pp = local.getResults().getPoi(0).point; //获取第一个智能搜索的结果
          handleMapCenter(pp.lng, pp.lat);
        }
        var local = new BMap.LocalSearch(map, {
          //智能搜索
          onSearchComplete: myFun
        });
        local.search(Value);
      }
    };
    //开启绘制功能
    const handleDraw = () => {
      const map = getMap()[0];
      handleClearOverlays(); //清除地图上所有覆盖物
      refData.mapDraw = map.drawMarker({}, e => {
        const {
          lng,
          lat
        } = e.getPosition();
        refData.lngLat = [lng, lat];
        getAreaList(refData.lngLat[0], refData.lngLat[1]);
        // 关闭绘制功能
        map.closeDraw(refData.mapDraw);
      });
    };
    //地址中心点设置
    const handleMapCenter = (lon, lat) => {
      nextTick(() => {
        const map = getMap()[0];
        map.setCenter([lon, lat]);
        refData.lngLat = [lon, lat];
        getAreaList(refData.lngLat[0], refData.lngLat[1]);
        map.setMap(map.addMarker([lon, lat], {}));
      });
    };
    //确定按钮
    const confirm = async () => {
      if (props.isConfirm) {
        if (!refData.tableSelect.length) return message.warning('请先勾选危险区域');
        emit('handleSuccess', {
          recreation: refData.tableSelect.filter(item => !item.typeName).map(item => {
            // BMap转WGS
            const {
              lon,
              lat
            } = bdDecrypt([item.location.lng, item.location.lat]);
            item.location.lng = lon;
            item.location.lat = lat;
            if (item.type === 2) {
              item.pointList = item.pointList.map(val => {
                const {
                  lon,
                  lat
                } = bdDecrypt([val.lon, val.lat]);
                return {
                  lon,
                  lat
                };
              });
            }
            return item;
          }),
          dangerWaters: refData.tableSelect.filter(item => item.typeName).map(item => {
            // BMap转WGS
            const {
              lon,
              lat
            } = bdDecrypt([item.location.lng, item.location.lat]);
            item.location.lng = lon;
            item.location.lat = lat;
            if (item.type === 2) {
              item.pointList = item.pointList.map(val => {
                const {
                  lon,
                  lat
                } = bdDecrypt([val.lon, val.lat]);
                return {
                  lon,
                  lat
                };
              });
            }
            return item;
          })
        });
      }
      refData.visible = false;
    };
    //清除所有覆盖物
    const handleClearOverlays = () => {
      const map = getMap()[0];
      map && map.instance.clearOverlays(); //清除地图上所有覆盖物
    };
    //勾选table
    const handleSelectionChange = data => {
      if (!props.isSearch) return false; //查看状态
      const map = getMap()[0];
      // 清空所有覆盖物
      refData.coverArr.map(item => {
        map.instance.removeOverlay(item.cover);
        map.instance.removeOverlay(item.marker);
      });
      refData.coverArr = [];
      // 添加全部覆盖物
      refData.tableSelect = data.map(item => {
        handleCircleLayer([item.location.lng, item.location.lat], item.name, item.id);
        return item;
      });
      // 设置地图中心点
      data.length && map.setCenter([data[data.length - 1].location.lng, data[data.length - 1].location.lat]);
    };
    // 获取危险区域
    const getAreaList = async (lng, lat) => {
      const {
        msg,
        data,
        code
      } = await httpApi.listDangerPath({
        lng,
        lat
      });
      if (code === 0) {
        const recreation = data.recreation.map(item => ({
          ...item,
          typeName: 0,
          type: 1
        }));
        const dangerWaters = data.dangerWaters.map(item => ({
          ...item,
          typeName: 1,
          type: 1
        }));
        refData.tableData = [...recreation, ...dangerWaters].map((item, index) => {
          return {
            ...item,
            id: index
          };
        });
        !refData.tableData.length && message.success('当前位置没有危险区域');
      } else {
        message.warning(msg);
      }
      ;
    };
    // 根据单位查询危险区域
    const getByschoolIdArea = async schoolId => {
      const {
        msg,
        data,
        code
      } = await API.listDangerPathBySchoolId({
        schoolId
      });
      if (code === 0) {
        const map = getMap()[0];
        refData.tableColumns[0].visible = false;
        refData.tableData = data.map((item, index) => {
          // WGS转BMap
          const {
            lon,
            lat
          } = bdEncrypt([item.centerLon, item.centerLat]);
          item.centerLat = lat;
          item.centerLon = lon;
          refData.coverArr = [];
          item.type === 1 ? handleCircleLayer([lon, lat], item.pathName, index) : handlePolygonLayer([lon, lat], item.pointList, item.pathName, index);
          return {
            ...item,
            name: item.pathName,
            typeName: 0
          };
        });
        refData.tableData.length && map.setCenter([refData.tableData[refData.tableData.length - 1].centerLon, refData.tableData[refData.tableData.length - 1].centerLat]);
        !refData.tableData.length && message.success('当前位置没有危险区域');
      } else {
        message.warning(msg);
      }
      ;
    };
    // 点击列表时
    const tableRowClick = data => {
      const map = getMap()[0];
      const onoff = data.hasOwnProperty('uid');
      map.setCenter([onoff ? data.location.lng : data.centerLon, onoff ? data.location.lat : data.centerLat]);
    };
    // 添加圆图形
    const handleCircleLayer = (point, label, id) => {
      if (!point.length) return;
      const map = getMap()[0];
      const cover = map.setCircle(point, 250, {
        strokeColor: '#F56C6C',
        strokeWeight: 6,
        strokeOpacity: 1
      });
      const marker = map.addMarker(point, {});
      const str = `<div class="overflow">${label}</div>`;
      marker.setLabel(new BMap.Label(str, {
        offset: new BMap.Size(-50, 24)
      }));
      map.setMap(marker);
      map.setZoom(16);
      refData.coverArr.push({
        cover,
        marker,
        id
      });
      return cover;
    };
    // 添加多边图形
    const handlePolygonLayer = (center, points, label, id) => {
      if (!points.length) return;
      const map = getMap()[0];
      const arr = points.map(item => {
        const {
          lon,
          lat
        } = bdEncrypt([item.lon, item.lat]);
        return [lon, lat];
      });
      const cover = map.setPolygon(arr, {
        strokeColor: '#F56C6C',
        strokeWeight: 6,
        strokeOpacity: 1
      });
      const marker = map.addMarker(center, {});
      const str = `<div class="overflow">${label}</div>`;
      marker.setLabel(new BMap.Label(str, {
        offset: new BMap.Size(-50, 24)
      }));
      map.setMap(marker);
      map.setZoom(16);
      refData.coverArr.push({
        cover,
        marker,
        id
      });
      return cover;
    };
    // 关闭编辑
    const closeEdit = row => {
      const map = getMap()[0];
      let i = null;
      refData.coverArr.map((item, index) => item.id == row.id && (i = index));
      if (row.type === 1) {
        refData.coverArr[i].cover = map.setCircle([row.location.lng, row.location.lat], 250, {
          strokeColor: '#F56C6C',
          strokeWeight: 6,
          strokeOpacity: 1
        });
      } else {
        const points = row.pointList.map(item => [item.lon, item.lat]);
        refData.coverArr[i].cover = map.setPolygon(points, {
          strokeColor: '#F56C6C',
          strokeWeight: 6,
          strokeOpacity: 1
        });
      }
    };
    // 自定义图形
    const handleEdit = row => {
      const map = getMap()[0];
      map.setCenter([row.location.lng, row.location.lat]);
      const active = refData.coverArr.filter(item => item.id == row.id);
      let i = null;
      refData.tableSelect.filter((item, index) => item.id == row.id && (i = index));
      if (row.id !== refData.activeId && refData.activeId != null) {
        return message.warning('当前正处于编辑状态，无法修改危险区域');
      } else if (row.id === refData.activeId) {
        refData.activeId = null;
        closeEdit(row);
        map.closeDraw(refData.mapDraw);
      } else {
        refData.activeId = row.id;
        refData.backupCover = active;
        map.instance.removeOverlay(active[0].cover);
        refData.mapDraw = map.drawPolygon({}, e => {
          active[0].cover = e;
          refData.tableSelect[i].pointList = e.getPath().map(item => ({
            lon: item.lng,
            lat: item.lat
          }));
          refData.tableSelect[i].type = 2;
          map.closeDraw(refData.mapDraw);
          refData.activeId = null;
        });
      }
    };
    return {
      ...toRefs(refData),
      confirm,
      initMapSearch,
      handleDraw,
      handleCircleLayer,
      handleMapCenter,
      handleSelectionChange,
      getByschoolIdArea,
      tableRowClick,
      handleEdit
    };
  }
};