
/** 绘制类型 */
export const drawTypeOptions = [
  {
    label: '圆形',
    value: 1,
  },
  {
    label: '多边形',
    value: 2,
  },
];


/** 围栏类型 */
export const fenceTypeOptions = [
  {
    label: '报警围栏',
    value: 1,
  },
  {
    label: '考勤围栏',
    value: 2,
  },
  {
    label: '危险区域',
    value: 3,
  },
];

/** 考勤类型 */
export const attendanceTypeOptions = [
  {
    label: '入围栏',
    value: 1,
  },
  {
    label: '出围栏',
    value: 2,
  },
];

/** 告警类型 */
export const alarmTypeOptions = [
  {
    label: '进入围栏报警',
    value: 1,
  },
  {
    label: '离开围栏报警',
    value: 2,
  },
];

/** 考勤日期 */
export const dateTypeOptions = [
  {
    label: '全选',
    value: 0,
  },
  {
    label: '周一到周日',
    value: 1,
  },
  {
    label: '节假日',
    value: 2,
  },
];

export const columns = [
  {
    title: '序号',
    align: 'center',
    key: 'index',
    width: 60,
    slots: { customRender: 'index' },
    visible: true,
  },
  {
    title: '围栏名称',
    align: 'center',
    key: 'pathName',
    visible: true,
  },
  // {
  //   title: '绘图类型',
  //   align: 'center',
  //   key: 'type',
  //   visible: true,
  //   slots: { customRender: 'type' },
  // },
  {
    title: '围栏类型',
    align: 'center',
    key: 'useType',
    visible: true,
    slots: { customRender: 'useType' },
  },
  {
    title: '报警类型',
    align: 'center',
    key: 'alarmType',
    visible: true,
    slots: { customRender: 'alarmType' },
  },
  {
    title: '操作',
    align: 'center',
    key: 'active',
    width: 100,
    slots: { customRender: 'active' },
    visible: true,
  },
];