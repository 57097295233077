import { defineComponent, toRefs, reactive, computed, watch, nextTick } from 'vue';
import Eldialog from '@/components/Eldialog/index.vue';
import Binding from './Binding.vue';
import { isNullOrUnDef } from '@/utils/is';
export default defineComponent({
  name: 'BindDialog',
  components: {
    Eldialog,
    Binding
  },
  emits: ['ok', 'update:visible'],
  props: {
    pathId: {
      type: Number,
      default: null
    },
    type: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {
    emit
  }) {
    const refData = reactive({
      title: '围栏绑定',
      refBinding: null
    });
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit('update:visible', val);
      }
    });
    watch(() => props.visible, async val => {
      if (val !== true) return;
      await nextTick();
      init();
    });
    function init() {
      if (isNullOrUnDef(refData.refBinding)) return;
      if (props.pathId === 0) return;
      refData.refBinding.type = props.type;
      refData.refBinding.getBinding(props.pathId);
    }
    async function confirm() {
      if (!isNullOrUnDef(refData.refBinding)) {
        const result = await refData.refBinding.save();
        if (result !== true) return;
      }
      show.value = false;
    }
    return {
      ...toRefs(refData),
      show,
      confirm
    };
  }
});